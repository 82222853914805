import React from 'react';

const Philosophy = () => {
    return (
        <div className={"flex flex-col items-center space-y-10 justify-center py-32"}>
            <div
                className={"lg:w-10/12 w-11/12 scale-ani text-white text-center lg:px-20 px-4 py-16 rounded-2xl bg-black"}>
                <h3 className={"lg:text-6xl text-3xl text-white font-bold"}> Ready to take your business to the next level? </h3> <br/> <br/>
                <h3 className={"text-xl lg:text-2xl text-white"}> With <span translate="no">Pitchle</span>, connecting and promoting has never been more efficient and impactful !</h3>
            </div>

        </div>
    );
};

export default Philosophy;